import React, { useState, useEffect } from 'react';
import { Form } from "@quillforms/renderer-core";
import "@quillforms/renderer-core/build-style/style.css";
import { registerCoreBlocks } from "@quillforms/react-renderer-utils";
import Swal from 'sweetalert2';
import "./App.css";
registerCoreBlocks();

const App = () => {
    const [formObj, setFormObj] = useState(null); // State to hold form object
    const [loading, setLoading] = useState(true); // Loading state
    const [error, setError] = useState(null); // Error state
    const [cohorts, setCohorts] = useState([]); // State for cohorts
    const [moduleSessions, setModuleSessions] = useState([]); // State for module sessions
    const [canSubmit, setCanSubmit] = useState(false);

    // Fetch formObj from the PHP API when the component loads
    useEffect(() => {
        fetch('https://absence.remote.ac/php-backend/form-api.php') // Replace with your actual PHP endpoint
            .then(response => {
                if (!response.ok) {
                    throw new Error('Failed to fetch form');
                }
                return response.json();
            })
            .then(data => {
                setFormObj(data); // Set the formObj from the response
                setLoading(false); // Set loading to false once data is fetched
            })
            .catch(err => {
                console.error('Error fetching form:', err);
                setError('Failed to load the form'); // Set error message
                setLoading(false);
            });
    }, []);

    // Function to handle fetching cohorts based on selected course
    const handleCourseChange = async (courseValue, setIsPending, setIsFieldValid, setFieldValidationErr, setIsCurrentBlockSafeToSwipe, goNext) => {
        try {
            setIsPending(true); // Show loading spinner
            const response = await fetch(`https://absence.remote.ac/php-backend/get-cohorts.php?course=${courseValue}`);
            if (!response.ok) {
                throw new Error('Failed to fetch cohorts');
            }
            const data = await response.json();
            setCohorts(data.cohorts);
            setIsPending(false); // Hide loading spinner
            setIsFieldValid("quest-course", true); // Set field as valid
            setIsCurrentBlockSafeToSwipe(true); // Allow user to swipe
            goNext(); // Move to the next block
        } catch (error) {
            console.error('Error fetching cohorts:', error);
            setIsPending(false); // Hide spinner on error
            setIsFieldValid("quest-course", false); // Set field as invalid
            setFieldValidationErr("quest-course", "Failed to fetch cohorts.");
            setIsCurrentBlockSafeToSwipe(false); // Prevent swiping
        }
    };

    // Function to handle fetching module sessions based on selected cohort
    const handleCohortChange = async (cohortValue, setIsPending, setIsFieldValid, setFieldValidationErr, setIsCurrentBlockSafeToSwipe, goNext) => {
        try {
            setIsPending(true); // Show loading spinner
            const response = await fetch(`https://absence.remote.ac/php-backend/get-modules.php?cohort=${cohortValue}`);
            if (!response.ok) {
                throw new Error('Failed to fetch module sessions');
            }
            const data = await response.json();

            // Add "All Day Absence" option at the top
            const updatedModuleSessions = [
                { label: "All Week Absence", value: "all-week-absence" },
                ...data.moduleSessions
            ];

            setModuleSessions(updatedModuleSessions);
            setIsPending(false); // Hide loading spinner
            setIsFieldValid("quest-cohort", true); // Set field as valid
            setIsCurrentBlockSafeToSwipe(true); // Allow user to swipe
            goNext(); // Move to the next block
        } catch (error) {
            console.error('Error fetching module sessions:', error);
            setIsPending(false); // Hide spinner on error
            setIsFieldValid("quest-cohort", false); // Set field as invalid
            setFieldValidationErr("quest-cohort", "Failed to fetch module sessions.");
            setIsCurrentBlockSafeToSwipe(false); // Prevent swiping
        }
    };

    // Function to handle form submission and post the data
    const handleSubmit = (formData, { completeForm, setIsSubmitting, setSubmissionErr }) => {
        const answers = formData.answers;

        // Ensure moduleSessions is submitted as an array if multiple sessions are selected
        let selectedModuleSessions = answers["quest-module-sessions"]?.value || [];

        // Check if "All Day Absence" is selected
        if (selectedModuleSessions.includes("all-week-absence")) {
            // Replace selectedModuleSessions with all module session values
            selectedModuleSessions = moduleSessions
                .filter(session => session.value !== "all-week-absence")
                .map(session => session.value);
        }

        // Prepare the data for sending, extracting the 'value' from each field safely
        const postData = {
            course: Array.isArray(answers["quest-course"]?.value) ? answers["quest-course"].value[0] : null,
            cohort: Array.isArray(answers["quest-cohort"]?.value) ? answers["quest-cohort"].value[0] : null,
            moduleSessions: selectedModuleSessions,
            wsNumber: answers["quest-ws-number"]?.value || null,
            absenceReason: answers["quest-absence-reason"]?.value || null,
            confirmSubmission: Array.isArray(answers["quest-confirm-submission"]?.value) ? answers["quest-confirm-submission"].value[0] : null
        };

        // Validate required fields before proceeding
        if (!postData.confirmSubmission) {
            setIsSubmitting(false);
            Swal.fire({
                title: 'Confirmation Required',
                html: 'Please confirm that you accept the terms and conditions before submitting the form.',
                icon: 'error',
                confirmButtonText: 'OK'
            });
            return;
        }
        else if (!postData.course || !postData.cohort || !postData.absenceReason) {
            setIsSubmitting(false);
            Swal.fire({
                title: 'Missing Information',
                html: 'Please make sure all required fields are filled in before submitting the form.',
                icon: 'error',
                confirmButtonText: 'OK'
            });
            return;
        }

        // Make the POST request to the PHP backend
        fetch('https://absence.remote.ac/php-backend/post-absence.php', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(postData)
        })
        .then(response => {
            if (response.ok) {
                return response.json();
            } else {
                throw new Error('There was an error submitting the form, please try again.');
            }
        })
        .then(data => {
            console.log('POST RES:', data);
            if (data.message === 'success') {
                setIsSubmitting(false);
                completeForm();
            } else {
                setIsSubmitting(false);
                Swal.fire({
                    title: data.title,
                    html: data.message,
                    icon: 'error',
                    confirmButtonText: 'OK',
                    footer: 'If this form is not working as expected, you still have an obligation to contact your module leads, program coordinators, and follow your company\'s absence policy.'
                });
            }
        })
        .catch(error => {
            console.error('Error:', error);
            setIsSubmitting(false);
            Swal.fire({
                title: 'Submission Error',
                html: 'An unexpected error occurred. Please try again later.',
                icon: 'error',
                confirmButtonText: 'OK'
            });
        });
    };


    // Update form with dynamic cohorts and module sessions based on selection
    const updateFormObj = () => {
        if (!formObj) return formObj;

        return {
            ...formObj,
            blocks: formObj.blocks.map(block => {
                if (block.id === 'quest-cohort') {
                    return {
                        ...block,
                        attributes: {
                            ...block.attributes,
                            choices: cohorts.map(cohort => ({
                                label: cohort.label,
                                value: cohort.value
                            }))
                        }
                    };
                }
                if (block.id === 'quest-module-sessions') {
                    return {
                        ...block,
                        attributes: {
                            ...block.attributes,
                            choices: moduleSessions.map(session => ({
                                label: session.label,
                                value: session.value
                            }))
                        }
                    };
                }
                return block;
            })
        };
    };

    // Display a loading message or error if necessary
    if (loading) {
        return <div>Loading form...</div>;
    }

    if (error) {
        return <div>{error}</div>;
    }

    // Handle course and cohort selection and asynchronous call with `beforeGoingNext`
    const handleBeforeGoingNext = async ({
        setIsFieldValid,
        setIsPending,
        currentBlockId,
        answers,
        setFieldValidationErr,
        setIsCurrentBlockSafeToSwipe,
        goNext
    }) => {
        if (currentBlockId === "quest-course" && answers["quest-course"]?.value[0]) {
            // Call the function to fetch cohorts when course is selected
            await handleCourseChange(
                answers["quest-course"]?.value[0],
                setIsPending,
                setIsFieldValid,
                setFieldValidationErr,
                setIsCurrentBlockSafeToSwipe,
                goNext
            );
        } else if (currentBlockId === "quest-cohort" && answers["quest-cohort"]?.value[0]) {
            // Call the function to fetch module sessions when cohort is selected
            await handleCohortChange(
                answers["quest-cohort"]?.value[0],
                setIsPending,
                setIsFieldValid,
                setFieldValidationErr,
                setIsCurrentBlockSafeToSwipe,
                goNext
            );
        } else {
            goNext(); // Move to next block if it's not course or cohort
        }
    };

    // Render the form only when the formObj is fetched and available
    return (
        <div className="App">
            <Form
                formId="1"
                formObj={updateFormObj()}  // Use the updated form object with dynamic cohorts and module sessions
                onSubmit={handleSubmit}
                beforeGoingNext={handleBeforeGoingNext}  // Handle async requests before going to next block
            />
        </div>
    );
}

export default App;
